// ==============================
// Custom style
// ==============================
// You can override the variables in _variables.scss to customize the style
.home-intro .home-social-links i {
  font-size: 2.8rem !important;
}

b, strong {
  box-shadow: 0 3px 0 #2d96bd;
}

.post-title {
  text-align: center !important;
}